import React, {useEffect, useMemo} from 'react'
import { get, isEmpty } from 'lodash'
import { Typography, CssBaseline } from '@material-ui/core/'
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles'
import { Stepper, Loader } from 'components'
import { useForm } from 'hooks/formsDisplay'
import Form from './Form'
import { StyledForm } from 'pages/styles'
import Title from 'components/title'
import FormProvider from 'providers/FormProvider'


const FormDisplay = () => {
	const { isLoading, form } = useForm()
	const themeColorMode = window.localStorage.getItem('theme');

	const themes = {
		light: {
			mode: 'light',
			palette: {
				primary: {
					main: (form.builder && form.builder.preferences) ? form.builder.preferences.color_theme : '#1F2733',
				},
				text: {
					primary: '#252525',
					secondary: '#3b3b3b'
				},
				background: {
					default: '#ffffff',
					paper: '#ffffff',
					body: '#ffffff'
				},
				typography: {
					fontFamily: (form.builder && form.builder.preferences) ? form.builder.preferences.font_family : 'Roboto, sans-serif',
					fontSize: (form.builder && form.builder.preferences) ? form.builder.preferences.font_size : 12,
				},
				overrides: {
					MuiCssBaseline: {
						'@global': {
							body: {
								backgroundColor: '#ffffff',
							},
							'.makeStyles-wrapper-1': {
								backgroundColor: '#f4f4f4', // Sua cor de fundo desejada para as divs
							},
						},
					},
				},
			},
		},


		dark: {
			mode: 'dark',
			palette: {
				primary: {
					main: (form.builder && form.builder.preferences) ? form.builder.preferences.color_theme_dark : '#1F2733',
					contrastText: '#ffffff'
				},
				text: {
					primary: '#fff',
					secondary: '#fff'
				},
				background: {
					default: (form.builder && form.builder.preferences) ? form.builder.preferences.color_background_theme_dark : '#ffffff',
					paper: (form.builder && form.builder.preferences) ? form.builder.preferences.color_background_theme_dark : '#ffffff',
					body: (form.builder && form.builder.preferences) ? form.builder.preferences.color_background_theme_dark : '#ffffff'
				},
				input: {
					borderColor: '#FFF'
				}
			},
			overrides: {
				MuiCssBaseline: {
					'@global': {
						body: {
							backgroundColor: (form.builder && form.builder.preferences) ? form.builder.preferences.color_background_theme_dark : '#ffffff',
						},
						'.makeStyles-wrapper-1': {
							backgroundColor: '#f4f4f4', // Sua cor de fundo desejada para as divs
						},
					},
				},
				MuiInput: {
					styleOverrides: {
					  root: {
						// Adicione aqui seus estilos personalizados
						backgroundColor: 'black',
						borderRadius: 200,
						padding: '10px 15px',
						border: '1px solid black',
						outline: '1px solid red'
					  },
					},
				  },
			},
			typography: {
				fontFamily: (form.builder && form.builder.preferences) ? form.builder.preferences.font_family : 'Roboto, sans-serif',
				fontSize: (form.builder && form.builder.preferences) ? form.builder.preferences.font_size : 12,
				h6: {
					color: '#ffffff'
				}
			},
			components: {
				MuiInput: {
				  styleOverrides: {
					root: {
					  // Adicione aqui seus estilos personalizados
					  backgroundColor: 'black',
					  borderRadius: 8,
					  padding: '10px 15px',
					  border: '1px solid red',
					},
				  },
				},
			  },
		},
	}

	const customTheme = createTheme(themeColorMode == 'dark' ? themes.dark : themes.light);
	const classes = useStyles(themes);

	console.log('tema', customTheme)

	const receipt = useMemo(
		() => (
			<Typography className={classes.instructions}>
				<div dangerouslySetInnerHTML={{ __html: form?.builder?.conclusion_message }} />
			</Typography>
		),
		[classes.instructions, form]
	)

	const defaultOrSavedAnswers = () => {
		if (form && !isEmpty(form.saved_answers))
			return form.saved_answers

		return form.default_answers
	}
	const parentUrl = (window.location !== window.parent.location)
		? document.referrer
		: document.location.href;

	const postMessage = () => {
		if (window.parent && parentUrl) {
			//form loaded post message
			window.parent.postMessage({
				type: "formLoaded",
				message: `Formulário carregado`,
				success: true
			}, parentUrl);
		}
	};

	useEffect(() => {
		if (!isLoading) {
			postMessage();
		}
	}, [isLoading]);

	if (isLoading) {
		return <div className={classes.wrapper}><Loader size={100} /></div>;
	}




	return (
		<Title newTitle={form?.builder?.title || 'Form'} >
			<div className={classes.wrapper} >
				<ThemeProvider theme={customTheme}>
					<CssBaseline />
					<FormProvider steps={get(form.builder, 'steps', []).length}>
						<Stepper
							steps={get(form.builder, 'steps', []).sort((a, b) => {
								return (a.order > b.order) ? 1 : -1
							})}
							send={true}
							receipt={receipt}
							customer={form.customer}
						>
							{(props) =>
								<StyledForm preferences={form && form.builder.preferences}>
									<Form formSteps={get(form.builder, 'steps', [])} customer={form.customer} defaultAnswers={defaultOrSavedAnswers()} {...props} />
								</StyledForm>
							}
						</Stepper>
					</FormProvider>
				</ThemeProvider>
			</div>
		</Title>
	)
}
const useStyles = (theme) => {

	return makeStyles((theme) => ({
		wrapper: {
			backgroundColor: theme.background.default,
			padding: '20px 0',
			margin: '60px 20px',
			display: 'flex',
			alignItems: 'left',
			flexDirection: 'column',
			fontFamily: '"Times New Roman" !important',
		},
		instructions: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			textAlign: 'center'
		},
	}))
}

FormDisplay.propTypes = {}

FormDisplay.defaultProps = {}

export default FormDisplay
